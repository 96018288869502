<template>
  <div>
    <a-row class="filter-container">
      <a-col :xxl="3" :xl="4" :lg="6" :sm="12" class="flex">
        模块：<a-input class="flex1" v-model="filter.cdMenuTitle" placeholder="菜单名"></a-input>
      </a-col>
      <a-col :xxl="3" :xl="4" :lg="6" :sm="12" class="flex">
        内容：<a-input class="flex1" v-model="filter.cdContent" placeholder="操作内容"></a-input>
      </a-col>
      <a-col :xxl="3" :xl="5" :lg="6" :sm="12" class="flex">
        开始时间：<a-date-picker class="flex1" :allowClear="true" v-model="filter.cdFromDt" placeholder="开始" format="YYYY-MM-DD" />
      </a-col>
      <a-col :xxl="3" :xl="5" :lg="6" :sm="12" class="flex">
        结束时间：<a-date-picker class="flex1" :allowClear="true" v-model="filter.cdToDt" placeholder="结束" format="YYYY-MM-DD" />
      </a-col>
      <a-col :xxl="3" :xl="4" :lg="6" :sm="12" class="flex">
        <a-button type="primary" @click="filter.pageNum=1;getLogList()">查询</a-button>
        <a-button type="default" class="clear-btn" @click="clearFilter">清空</a-button>
      </a-col>
    </a-row>
    <a-row>
      <a-col :lg="24" :sm="24">
        <a-table :scroll="{y:Number(contHeight)}" :columns="columns" :data-source="garageList" :pagination="false" :bordered="true">
          <span slot="ind" slot-scope="record,text,index">{{index+1}}</span>
        </a-table>
      </a-col>
    </a-row>
    <div style="padding-top:20px;">
      <a-pagination style="float:right;text-align:center" @change="changPage" :total="total" :pageSize="filter.pageSize" />
    </div>
  </div>
</template>
<script>
  const columns = [
    {
      title: '序号',
      scopedSlots: {customRender: 'ind'},
      align:'center'
    },
    {
      title: '所属公司',
      dataIndex: 'companyName',
      align:'center'
    },
    {
      title: '操作模块',
      dataIndex: 'moduleName',
      align:'center'
    },
    {
      title: '操作内容',
      dataIndex: 'content',
      align:'center',
      width:500
    },
    {
      title: '本地IP',
      dataIndex: 'localIp',
      align:'center'
    },
    {
      title: '操作时间',
      dataIndex: 'actionDt',
      align:'center'
    },
  ];
  export default {
    data() {
      return {
        columns,
        garageList:[],
        filter:{
          pageNum:1,
          pageSize:20,
          cdFromDt:'',
          cdToDt:'',
          examSpotId:this.$store.state.curSpotId
        },
        contHeight:0,
        total:0,
      };
    },
    methods:{
      getLogList(){
        if(this.filter.cdFromDt)this.filter.cdFromDt = this.$moment(this.filter.cdFromDt).format('YYYY-MM-DD')
        if(this.filter.cdToDt)this.filter.cdToDt = this.$moment(this.filter.cdToDt).format('YYYY-MM-DD')
        this.$post(this.$url.考场操作日志, this.filter).then(data => {
          this.garageList = data.data.list
          this.total = data.data.total
        }).catch(() => {});
      },
      clearFilter(){
        this.filter = {
          pageNum:1,
          pageSize:20,
          cdFromDt:null,
          cdToDt:null,
          examSpotId:this.$store.state.curSpotId
        }
        this.getLogList()
      },
      changPage(page){
        this.filter.pageNum = page;
        this.getLogList()
      }
    },
    mounted(){
      this.contHeight = Number(document.body.offsetHeight) -300
      this.getLogList()
    }
  };
</script>
